import { GlobalState } from '@resources/reducers';
import { Config } from '@resources/config/_config';
import { DocTemplate } from '@resources/config/_doc-template';

export const selectConfig = (state: GlobalState): Config => state.config.config;
export const selectDocsTemplates = (state: GlobalState): DocTemplate[] =>
    state.config.config.docs_templates;

export const selectDocsTemplatesByNames = (
    state: GlobalState,
    docsNames: string[],
): DocTemplate[] => {
    const docsTemplates = selectDocsTemplates(state);
    return docsNames
        .filter((docName) => docsTemplates.find((docTemplate) => docTemplate.name !== docName))
        .map((docName) => {
            return docsTemplates.find((docTemplate) => docTemplate.name === docName);
        })
        .filter((docName) => !!docName);
};
