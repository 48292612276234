export const FORMAT_DATE = 'DD MMMM YYYY';
export const FORMAT_DATE_SHORT = 'DD.MM.YYYY';
export const FORMAT_TIME = 'HH:mm:ss';
export const FORMAT_TIME_SHORT = 'HH:mm';
export const FORMAT_DATETIME = 'DD MMMM YYYY в HH:mm';
export const FORMAT_DATETIME_FILE = 'DD-MM-YYYY_HH-mm';

export function reverseStrDate(str: string): string {
    if (!str) {
        return str;
    }
    return str.split('.').reverse().join('.');
}
