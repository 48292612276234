import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'reflexbox';
import { Button, Intent } from '@blueprintjs/core';

import { OrgItem } from '@components/org-item';
import { AlertRemove } from '@components/alert-remove';
import { FormLabel } from '@components/ui/form-label';
import { useOrgsToaster } from '@components/use-orgs-toaster';

import { Layout } from '@containers/layout';

import { GlobalState } from '@resources/reducers';
import { OrgsActions } from '@resources/actions/_state';
import { selectOrgsActions } from '@resources/actions/selectors';
import { initialOrg, Org } from '@resources/orgs/_org';
import { selectOrgById, selectOrgs } from '@resources/orgs/selectors';
import { orgCreate, orgRemove, orgs, orgUpdate } from '@resources/orgs/actions';
import { User, USER_ROLE } from '@resources/users/_user';
import { selectProfile } from '@resources/users/selectors';
import { visitsOrgs } from '@resources/cards/actions';
import { Visit } from '@resources/cards/_visit';
import { selectVisitsByOrg, selectVisitsByOrgs } from '@resources/cards/selectors';

type Props = {
    orgs: Org[];
    orgsActions: OrgsActions;
    profile: User;
    visits: Visit[];
};

export default connect(mapStateToProps)((props: Props) => {
    const dispatch = useDispatch();
    useOrgsToaster({
        orgsActions: props.orgsActions,
    });

    const [removeOrgId, setRemoveOrgId] = React.useState<number>(0);
    const org = useSelector((state: GlobalState) => selectOrgById(state, removeOrgId));

    const onUserUpdate = (org: Org) => dispatch(orgUpdate({ org: org }));

    const onOrgCreate = () => {
        dispatch(orgCreate({ org: { ...initialOrg } }));
    };

    const onOrgRemove = (orgId: number) => {
        dispatch(orgRemove({ org_id: orgId }));
    };

    React.useEffect(() => {
        dispatch(orgs());
        dispatch(visitsOrgs({}));
    }, []);

    return (
        <Layout isLoading={props.orgsActions.orgs.loading} title="Организации">
            <Flex flexDirection="column" mb={30}>
                <FormLabel>Организации</FormLabel>
                <Box style={{ marginBottom: '20px' }}>
                    <Button intent={Intent.SUCCESS} icon="add" onClick={onOrgCreate}>
                        Добавить организацию
                    </Button>
                </Box>

                <Box>
                    {props.orgs.map((org) => (
                        <OrgItem
                            key={org.id}
                            org={org}
                            isDisabledRemove={props.profile.role === USER_ROLE.VIEWER}
                            onUpdate={(u) => onUserUpdate(u)}
                            onRemove={() => setRemoveOrgId(org.id)}
                            visits={selectVisitsByOrg(props.visits, org.id)}
                        />
                    ))}
                </Box>
            </Flex>

            <AlertRemove
                isOpen={removeOrgId !== 0}
                onClose={() => setRemoveOrgId(0)}
                onConfirm={() => onOrgRemove(removeOrgId)}>
                Удалить организацию? <b>{org.name}</b>?
            </AlertRemove>
        </Layout>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        orgs: selectOrgs(state),
        orgsActions: selectOrgsActions(state),
        profile: selectProfile(state),
        visits: selectVisitsByOrgs(state),
    };
}
