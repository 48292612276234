import React from 'react';
import { Intent } from '@blueprintjs/core';

import { AppToaster } from '@components/ui/toaster';
import { useCanNav } from '@components/ui/use-can-nav';

import { OrgsActions } from '@resources/actions/_state';

type Props = {
    orgsActions: OrgsActions;
};

export const useOrgsToaster = (props: Props) => {
    useCanNav(props.orgsActions.org_create.success, () => {
        AppToaster.show({
            message: 'Организация создана',
            intent: Intent.SUCCESS,
            icon: 'tick',
        });
    });

    useCanNav(props.orgsActions.org_update.success, () => {
        AppToaster.show({
            message: 'Организация обновлена',
            intent: Intent.PRIMARY,
            icon: 'tick',
        });
    });

    useCanNav(props.orgsActions.org_remove.success, () => {
        AppToaster.show({
            message: 'Организация удалена',
            intent: Intent.PRIMARY,
            icon: 'trash',
        });
    });
};
