import React from 'react';
import { Alert, Intent } from '@blueprintjs/core';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    children: string | React.ReactChild | React.ReactChild[];
};

export const AlertRemove = (props: Props) => {
    return (
        <Alert
            confirmButtonText="Удалить"
            cancelButtonText="Закрыть"
            icon="trash"
            intent={Intent.DANGER}
            isOpen={props.isOpen}
            canOutsideClickCancel={true}
            onClose={props.onClose}
            onConfirm={props.onConfirm}>
            {props.children}
        </Alert>
    );
};
