import React from 'react';

export function useCanNav<T>(unix: number | T, callback: () => void): [] {
  const [can, setCan] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (unix && can) {
      callback();
    }
    setCan(true);
    return () => {
      setCan(false);
    };
  }, [unix]);

  return [];
}
