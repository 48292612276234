import React from 'react';
import { Label } from '@blueprintjs/core';

import * as styles from './index.module.scss';

type Props = {
    children?: React.ReactChild | React.ReactChild[] | string;
    rightContent?: React.ReactChild | React.ReactChild[];
};

export const FormLabel = (props: Props) => (
    <Label className={styles.label}>
        {props.children}
        {props.rightContent}
    </Label>
);
