import { actionCreate, actionFlow } from '@resources/helpers/redux';

import { CARDS_CONST } from '@resources/cards/constants';
import {
    AddressSuggestsPayload,
    CardCreatePayload,
    CardPayload,
    CardRemovePayload,
    CardsPayload,
    CardsSuggestsPayload,
    CardUpdatePayload,
    IssuedSuggestsPayload,
    VisitsOrgsPayload,
    VisitsPayload,
} from '@resources/cards/interfaces';
import {
    repoCards,
    repoCardCreate,
    repoCardUpdate,
    repoCardRemove,
    repoVisits,
    repoCardsSuggests,
    repoCard,
    repoVisitsOrgs,
} from '@resources/cards/repo';

export const cards = (payload: CardsPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_CARDS_FETCH,
        CARDS_CONST.CARDS_CARDS_SUCCESS,
        CARDS_CONST.CARDS_CARDS_FAIL,
        async () =>
            await repoCards({
                ...payload.pagination,
                ...payload.filters,
            }),
    );

export const cardCreate = (payload: CardCreatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_CARD_CREATE_FETCH,
        CARDS_CONST.CARDS_CARD_CREATE_SUCCESS,
        CARDS_CONST.CARDS_CARD_CREATE_FAIL,
        async () =>
            await repoCardCreate({
                ...payload.card,
                ...payload.extra,
                address_data1: JSON.stringify(payload.card.address_data1),
                address_data2: JSON.stringify(payload.card.address_data2),
                prices: JSON.stringify(payload.extra.prices),
            }),
    );

export const cardUpdate = (payload: CardUpdatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_CARD_UPDATE_FETCH,
        CARDS_CONST.CARDS_CARD_UPDATE_SUCCESS,
        CARDS_CONST.CARDS_CARD_UPDATE_FAIL,
        async () =>
            await repoCardUpdate({
                ...payload.card,
                ...payload.extra,
                address_data1: JSON.stringify(payload.card.address_data1),
                address_data2: JSON.stringify(payload.card.address_data2),
                prices: JSON.stringify(payload.extra.prices),
            }),
    );

export const cardRemove = (payload: CardRemovePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_CARD_REMOVE_FETCH,
        CARDS_CONST.CARDS_CARD_REMOVE_SUCCESS,
        CARDS_CONST.CARDS_CARD_REMOVE_FAIL,
        async () => {
            await repoCardRemove(payload);
            return { card_id: payload.card_id };
        },
    );

export const visits = (payload: VisitsPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_VISITS_FETCH,
        CARDS_CONST.CARDS_VISITS_SUCCESS,
        CARDS_CONST.CARDS_VISITS_FAIL,
        async () =>
            await repoVisits({
                ...payload.pagination,
                ...payload.filters,
            }),
    );

export const cardsSuggests = (payload: CardsSuggestsPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_CARDS_SUGGESTS_FETCH,
        CARDS_CONST.CARDS_CARDS_SUGGESTS_SUCCESS,
        CARDS_CONST.CARDS_CARDS_SUGGESTS_FAIL,
        async () => await repoCardsSuggests(payload),
    );

export const addressSuggests = (payload: AddressSuggestsPayload) =>
    actionCreate(CARDS_CONST.CARDS_ADDRESS_SUGGESTS_FETCH, payload);

export const card = (payload: CardPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_CARD_FETCH,
        CARDS_CONST.CARDS_CARD_SUCCESS,
        CARDS_CONST.CARDS_CARD_FAIL,
        async () => await repoCard(payload),
    );

export const issuedSuggests = (payload: IssuedSuggestsPayload) =>
    actionCreate(CARDS_CONST.CARDS_ISSUED_SUGGESTS_FETCH, payload);

export const visitsOrgs = (payload: VisitsOrgsPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CARDS_CONST.CARDS_VISITS_ORGS_FETCH,
        CARDS_CONST.CARDS_VISITS_ORGS_SUCCESS,
        CARDS_CONST.CARDS_VISITS_ORGS_FAIL,
        async () => await repoVisitsOrgs(payload),
    );
