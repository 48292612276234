import cookie from 'js-cookie';

import { IS_BROWSER } from '@resources/helpers/ssr';
import { buildQuery } from '@resources/helpers/request';

// Распечатать документ
export const printDocument = (props: {
    url: string;
    query: any;
    onError: (err: Error) => void;
    onLoadingStart: () => void;
    onLoadingEnd: () => void;
}) => {
    if (IS_BROWSER) {
        const printJS = require('print-js');
        const accessToken = cookie.get('access_token');

        const query = buildQuery({
            ...props.query,
            access_token: accessToken,
        });

        printJS({
            printable: `${props.url}${query}`,
            type: 'pdf',
            onError: props.onError,
            onLoadingEnd: props.onLoadingEnd,
            onLoadingStart: props.onLoadingStart,
        });
    }
};
