import moment from 'moment-timezone';

import { GlobalState } from '@resources/reducers';
import { Card, CARD_TYPE, initialCard } from '@resources/cards/_card';
import { CardSuggest } from '@resources/cards/_card-suggest';
import { initialVisit, Visit } from '@resources/cards/_visit';
import { VisitPrice } from '@resources/cards/_visit-price';
import { selectUserById } from '@resources/users/selectors';
import { initialVisitCard } from '@resources/cards/_visit-card';
import { selectOrgById } from '@resources/orgs/selectors';
import { selectDocsTemplatesByNames } from '@resources/config/selectors';
import { selectOfficeById } from '@resources/offices/selectors';
import { Document } from '@resources/cards/_document';

// --
export const selectVisitsPricesByVisitId = (state: GlobalState, visitId: number): VisitPrice[] => {
    return state.cards.visits_prices.filter((visitPrice) => visitPrice.visit_id === visitId);
};

// --
export const selectVisitsByCard = (state: GlobalState, cardId: number): Visit[] => {
    return state.cards.visits
        .filter((visit) => visit.card_id === cardId)
        .map((visit): Visit => {
            const prices = selectVisitsPricesByVisitId(state, visit.id);
            return {
                ...visit,
                total: prices.reduce((prev, cur) => prev + cur.price, 0),
                _prices: prices,
                _user: {
                    ...visit._user,
                    ...selectUserById(state, visit.user_id),
                },
                _org: selectOrgById(state, visit.org_id),
                _docs_templates: selectDocsTemplatesByNames(state, visit.docs),
                _office: selectOfficeById(state, visit.office_id),
                _documents: selectDocumentsByVisitIds(state, visit.id),
                _card: selectCard(state, visit.card_id),
            };
        })
        .sort((a, b) => moment(b.docs_datetime).unix() - moment(a.docs_datetime).unix());
};

// --
export const selectCards = (state: GlobalState, type: CARD_TYPE): Card[] =>
    state.cards.cards_ids
        .map((cardId) => {
            const card = state.cards.cards.find((c) => c.id === cardId);
            return card ? card : { ...initialCard };
        })
        .filter((card) => card.id !== 0)
        .filter((card) => card.type === type)
        .map((card) => {
            return {
                ...card,
                _visits: selectVisitsByCard(state, card.id),
                _user: {
                    ...card._user,
                    username: selectUserById(state, card.user_id).username,
                },
                _org: selectOrgById(state, card.org_id),
            };
        });

// --
export const selectCard = (state: GlobalState, cardId: number): Card => {
    const card = state.cards.cards.find((card) => card.id === cardId);
    return card ? card : { ...initialCard };
};

export const selectDocumentsByVisitIds = (state: GlobalState, visitId: number): Document[] => {
    return state.cards.documents.filter((document) => document.visit_id === visitId);
};

// --
export const selectCardSuggests = (state: GlobalState): CardSuggest[] => {
    return state.cards.cards_suggests;
};

// --
export const selectAddressSuggests = (state: GlobalState) => state.cards.address_suggests;

// --
export const selectVisits = (state: GlobalState): Visit[] =>
    state.cards.visits_ids
        .map((visitId) => {
            const visit = state.cards.visits.find((visit) => visit.id === visitId);
            return visit ? visit : { ...initialVisit };
        })
        .filter((visit) => visit.id !== 0)
        .map((visit) => {
            const card = selectCard(state, visit.card_id);
            const prices = selectVisitsPricesByVisitId(state, visit.id);
            return {
                ...visit,
                total: prices.reduce((prev, cur) => prev + cur.price, 0),
                _card: {
                    ...initialVisitCard,
                    ...card,
                },
                _prices: prices,
                _office: selectOfficeById(state, visit.office_id),
            };
        });

// --
export const selectVisitsByOrgs = (state: GlobalState): Visit[] =>
    state.cards.visits
        .filter((visit) => visit.org_id !== 0)
        .map((visit) => {
            const card = selectCard(state, visit.card_id);
            return {
                ...visit,
                _card: {
                    ...initialVisitCard,
                    ...card,
                },
            };
        });

// --
export const selectVisitsByOrg = (visits: Visit[], orgId: number): Visit[] => {
    return visits.filter((visit) => visit.org_id === orgId);
};

// --
export const selectCardLast = (state: GlobalState): Card => {
    const cards = state.cards.cards.sort(
        (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix(),
    );

    if (cards.length) {
        return cards[0];
    }

    return { ...initialCard };
};

// --
export const selectVisitLast = (state: GlobalState): Visit => {
    const visits = state.cards.visits.sort(
        (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix(),
    );

    if (visits.length) {
        return {
            ...visits[0],
        };
    }

    return {
        ...initialVisit,
    };
};

// --
export const selectIssuedSuggests = (state: GlobalState): string[] => {
    return state.cards.issued_suggests;
};
