import { Intent } from '@blueprintjs/core';
import React from 'react';

import { AppToaster } from '@components/ui/toaster';

import { printDocument } from '@resources/helpers/print';

export const usePrintDocument = (): [boolean, (path: string, query: any) => void] => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const print = (path: string, query: any) => {
        printDocument({
            url: `${process.env.HOST_API}${path}`,
            query: query,
            onError: (err) => {
                AppToaster.show({
                    message: 'Ошибка при открытии - ' + err,
                    intent: Intent.DANGER,
                    icon: 'error',
                });
            },
            onLoadingEnd: () => setLoading(false),
            onLoadingStart: () => setLoading(true),
        });
    };

    return [loading, print];
};
