import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormGroup, InputGroup } from '@blueprintjs/core';
import { Flex } from 'reflexbox';
import moment from 'moment-timezone';

import * as styles from './index.module.scss';

import { usePrintDocument } from '@components/use-print-document';

import { Org } from '@resources/orgs/_org';
import { Visit } from '@resources/cards/_visit';
import { FORMAT_DATETIME } from '@resources/helpers/datetime';

type Props = {
    org: Org;
    visits: Visit[];
    onRemove: () => void;
    onUpdate: (org: Org) => void;
    isDisabledRemove?: boolean;
};

export const OrgItem = (props: Props) => {
    const [org, setOrg] = React.useState<Org>(props.org);
    const [printLoading, print] = usePrintDocument();
    const [visitsIds, setVisitsIds] = useState<number[]>([]);

    const onPrint = () => {
        print(`/v1/orgs/${props.org.id}/doc`, {
            visits_ids: visitsIds.join(','),
        });
    };

    const onClickCheckbox = (visitId: number) => {
        if (visitsIds.includes(visitId)) {
            setVisitsIds(visitsIds.filter((vId) => vId !== visitId));
        } else {
            setVisitsIds([...visitsIds, visitId]);
        }
    };

    useEffect(() => {
        setVisitsIds(props.visits.map((visit) => visit.id));
    }, [props.visits]);

    return (
        <div className={styles.container}>
            <div className={styles.number}>ID: {props.org.id}</div>
            <Flex width={['100%']}>
                <FormGroup label="Имя" style={{ marginRight: 10 }}>
                    <InputGroup
                        value={org.name}
                        style={{ minWidth: '300px' }}
                        placeholder="ООО Клиника"
                        onChange={(e) =>
                            setOrg({
                                ...org,
                                name: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <div className={styles.buttons}>
                    <Button icon="print" onClick={onPrint} loading={printLoading}>
                        Распечатать список
                    </Button>
                    <Button icon="floppy-disk" onClick={() => props.onUpdate(org)}>
                        Сохранить
                    </Button>
                    <Button disabled={props.isDisabledRemove} icon="trash" onClick={props.onRemove}>
                        Удалить
                    </Button>
                </div>
            </Flex>
            <ul>
                {props.visits.map((visit) => (
                    <li
                        key={visit.id}
                        style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
                        <Checkbox
                            checked={visitsIds.includes(visit.id)}
                            onClick={() => {
                                onClickCheckbox(visit.id);
                            }}
                        />
                        <div onClick={() => onClickCheckbox(visit.id)}>
                            {visit._card.unique_id} / {visit._card.last_name}{' '}
                            {visit._card.first_name} {visit._card.patronymic} /{' '}
                            {moment.utc(visit.docs_datetime).local().format(FORMAT_DATETIME)}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
